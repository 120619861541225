.subscription{
    font-size: 21px;

    &.subscription_final{
        width: 85%;
        line-height: 24px;
    }
    .instruction_title{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .instruction_list{
        list-style: none;
        padding: 0;
        li{
            margin-top: 10px;
        }
    }
    .instruction{
        margin-bottom: 40px;
    }
    @media screen and (max-width: 500px){
        font-size: 18px;
    }
}
