.suggestions {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 1000;
  &-top {
    top: 100%;
  }
  &-bottom {
    bottom: calc(100% + 15px);
  }
  &__list {
    max-height: 260px;
    overflow-y: scroll;
  }
  &__item {
    font-size: 16px;
    line-height: 2;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      line-height: 1.5;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__wrapper {
    position: relative;
  }
}