.range_input {
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 12px;
    height: 7px;
    outline: none;
    &::-webkit-slider-thumb{
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      background-color: #C4C4C4;
      border: 1px solid black;
      border-radius: 50%;
      cursor: pointer;
      transition-property: background-color;
      transition-duration: 0.3s;
      &:hover{
        background-color: #a1a1a1;
        transition-property: background-color;
        transition-duration: 0.3s;
      }
    }
}