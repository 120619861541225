.personalData_form{
    .personalData_block {
        .fields{
            margin: -15px;
            .field{
                margin: 15px;
            }
        }
    }
    .submitButton{
        margin-top: 30px;
    }
}

.final-success {
  text-align: center;
  margin: 15px 0;
  color: #00860a;
}

.twofa_modal {
  .twofa_modal_input {
    border: initial;
    text-align: center;
    border: 1px solid #ced4da;
    border-radius: 25rem;
  }

  .modal-body {
    flex-direction: column;
  }
}