.tabletPage {
  background: #000;
  &-logo {
    width: 320px;
    display: block;
    margin: auto;
    height: 10%;
    @media screen and (max-width: 1440px) {
      width: 190px;
    }
  }
  &-links {
    height: 90%;
  }
  &-link {
    color: #fff;
    transition: .3s ease;
    background-size: cover;
    background-position: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    @media screen and (max-width: 1020px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
    &:hover {
      transform: scale(1.1);
      text-decoration: none;
      color: #fff;
    }
    &__yachts {
      background-image: url("../../styles/assets/images/menu/school_bg.png");
    }
    &__sport {
      background-image: url("../../styles/assets/images/menu/sport_bg.png");
    }
  }
}