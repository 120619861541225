@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../../assets/fonts/Grotesque/basis-grotesque-black-pro.eot');
  src: url('../../assets/fonts/Grotesque/basis-grotesque-black-pro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Grotesque/basis-grotesque-black-pro.woff') format('woff'),
  url('../../assets/fonts/Grotesque/basis-grotesque-black-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../../assets/fonts/Grotesque/basis-grotesque-bold-pro.eot');
  src: url('../../assets/fonts/Grotesque/basis-grotesque-bold-pro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Grotesque/basis-grotesque-bold-pro.woff') format('woff'),
  url('../../assets/fonts/Grotesque/basis-grotesque-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../../assets/fonts/Grotesque/basis-grotesque-medium-pro.eot');
  src: url('../../assets/fonts/Grotesque/basis-grotesque-medium-pro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Grotesque/basis-grotesque-medium-pro.woff') format('woff'),
  url('../../assets/fonts/Grotesque/basis-grotesque-medium-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../../assets/fonts/Grotesque/basis-grotesque-mono-bold-pro.eot');
  src: url('../../assets/fonts/Grotesque/basis-grotesque-mono-bold-pro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Grotesque/basis-grotesque-mono-bold-pro.woff') format('woff'),
  url('../../assets/fonts/Grotesque/basis-grotesque-mono-bold-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../../assets/fonts/Grotesque/basis-grotesque-mono-regular-pro.eot');
  src: url('../../assets/fonts/Grotesque/basis-grotesque-mono-regular-pro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Grotesque/basis-grotesque-mono-regular-pro.woff') format('woff'),
  url('../../assets/fonts/Grotesque/basis-grotesque-mono-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Basis Grotesque Pro';
  src: url('../../assets/fonts/Grotesque/basis-grotesque-regular-pro.eot');
  src: url('../../assets/fonts/Grotesque/basis-grotesque-regular-pro.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/Grotesque/basis-grotesque-regular-pro.woff') format('woff'),
  url('../../assets/fonts/Grotesque/basis-grotesque-regular-pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Menoe Grotesque Pro';
  src: url('../../assets/fonts/MenoeGrotesquePro/MenoeGrotesquePro.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Menoe Grotesque Pro';
  src: url('../../assets/fonts/MenoeGrotesquePro/MenoeGrotesqueProItalic.otf');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Anjhay';
  src: url(../../../styles/assets/fonts/Anjhay/Anjhay.otf);
  font-weight: normal;
}

@font-face {
  font-family: 'DrukWide';
  src: url(../../../styles/assets/fonts/DrukWide/DrukWideBold.otf);
  font-weight: normal;
}