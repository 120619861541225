.alter-capacity {
  &-container {
    position: relative;
  }
  &-inner {
    top: 0;
    left: 50%;
    line-height: 12px;
    text-align: center;
    font-size: 14px;
  }
}

.container {
  .counter {
    align-items: center;
    margin-top: 24px;
    padding-left: 33px;
    @media screen and (max-width: 575px) {
      margin-top: 14px;
      padding-left: 15px;
    }
    &>div {
      margin: 0 10px;
      font-size: 20px;
      flex: 0 0 115px;
      text-align: center;
      @media screen and (max-width: 575px) {
        font-size: 18px;
      }
    }
    .counter__btn {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      border: 1px solid #000;
      background-color: #fff;
      opacity: 1;
      -webkit-transition: .3s;
      transition: .3s;
      color: black;
      font-size: 40px;
      position: relative;
      cursor: pointer;
      &:hover {
        border-color: #1140FF;
        background: none;
        &:before,
        &:after {
          background-color: #1140FF;
        }
        &:active {
          // outline: none;
          box-shadow: none;
          background-color: #000;
          border-color: #000;
          &:before,
          &:after {
            background-color: #fff;
          }
        }
      }
      &.disabled {
        background: transparent;
        border: 1px solid #d9d9d9;
        &:before,
        &:after {
          background-color: #d9d9d9;
        }
      }
      
      &:focus {
        // outline: none;
        box-shadow: none;
        // background-color: #000;
        border-color: #000;
        &:before,
        &:after {
          background-color: #000; 
        }
        &:active {
          box-shadow: none;
        }
      }
      
      &:before,
      &:after {
        content: "";
        background-color: #000;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        transition: .3s;
      }
      &:before {
        width: 24px;
        height: 1px;
      }
      &.increase {
        &:after {
          width: 1px;
          height: 24px;
        }
      }
    }
  }
}
