.subscription_info_component {
  font-size: 14px;
  border: 1px solid;
  border-radius: 10px;
  padding: 20px 25px;
  margin-top: 22px;

  .subscription_info_price {
    margin-bottom: 6px;
  }

  &.warning {
    border-color: #E50000;
    color: #E50000;
  }

  &.success {
    border-color: #205CDC;
    color: #205CDC;
  }

  ul {
    margin-top: 6px;
    list-style-position: inside;
    padding: 0;
    margin-bottom: 0;
    li{
      margin-top: 3px;
    }
  }
  @media screen and (max-width: 400px) {
    border-radius: 0;
    border-left: none;
    border-right: none;
    padding: 10px 0;
  }
}