.button_changePhone, .button_getNewCode{
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.timer {
  width: 100%;
  text-align: center;
}
.sentCode_phone{
  margin-right: 6px;
}