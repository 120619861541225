@keyframes move {
  50% {opacity: 0.3;}
}

.login-logo span {
  &:nth-child(1) {
    animation: move 5s infinite;
  }

  &:nth-child(2) {
    animation: move 5s infinite;
    animation-delay: 1s;
  }

  &:nth-child(3) {
    animation: move 5s infinite;
    animation-delay: 2s;
  }
}