.subscription-card {
  .field {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .primary-info {
    background: url(../../../styles/assets/images/card/deposit.jpg);
    background-size: cover;
  }
  .price {
    line-height: 40px;
  }
}