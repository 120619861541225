.react-tel-input .form-control {
    width: 100%;
}
.validation-img {
    position: relative;
    margin-left: 5px;
    top: -2px;
}
input[type="tel"],input[type="tel"]:focus,input[type="tel"]:active{
    outline: none;
    box-shadow: none;
}