.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  &__image {
    animation: spin 1s linear infinite;
    @keyframes spin {
      from {transform:rotate(360deg);}
      to {transform:rotate(0deg);}
    }
  }
}