.arrival {
  padding: 0 35px;
  &__item {
    margin-bottom: 20px;
    &-value {
      display: block;
      padding: 11px 0;
      font-size: 20px;
    }
  }
}