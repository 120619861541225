.field{
    .input-label{
        display: block;
    }
    .validation-img{
        position: relative;
        margin-left: 5px;
        top: -2px;
    }
    input[type="radio"] {
        display: none;
        & + label {
          position: relative;
          cursor: pointer;
          color: rgba(204, 204, 204, 1);
          margin-top: 3px;
          padding: 0 10px;
          font-size: 20px;
          &:nth-of-type(1) {
            margin-left: -10px;
          }
        }
        &:checked + label {
          color: #000;
          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 40px;
            height: 40px;
            border: 1px solid black;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%)
          }
        }
      }
}