.certificate-register-container{
    padding: 0 32px 32px 32px;
    .certificate-desctiption{
        background: #C4C4C4;
        font-size: 20px;
        @media screen and (max-width:1100px) {
            margin-right: 40px;
        }
        @media screen and (max-width:991px) {
            margin-right: 0;
        }
    }
    input[type='tel'], input[type='tel']:focus, input[type='tel']:active{
        background-color: transparent;
        box-shadow: none;
    }
    input[type="number"] {
        outline: none;
        box-shadow: none;
        &:focus, &:active{
            outline: none;
            box-shadow: none;
        }
    }
    textarea{
        width: 100%;
        border: none;
        outline: none;
        background-color: transparent;
    }
    .inputs{
        margin-top: 20px;
    }
    label[for="sertificate_sum"]{
        margin-bottom: 0;
    }
    .sertificate_sum{
        max-width: 110px;
        height: 22px;
    }
    .info-label{
        margin-top: 7px;
        color: rgb(61, 61, 61);
    }
    .validation-img{
        position: relative;
        margin-left: 5px;
        top: -2px;
    }
    .checkout__input-wrapper{
        margin-left: 0;
        margin-right: 0;
    }
    .sendRecipient{
        width: auto;
    }
    .certificate-number-copy{
        width: 16px;
        height: 17px;
        margin-left: 48px;
    }
    .certificate-number{
        display: inline-block;
        font-size: 21px;
        margin-top: 20px;
        margin-bottom: 20px;
        .certificate-number-value{
            margin-top: 10px;
            padding: 5px 10px;
            border: 1px solid black;
            border-radius: 10px;
            button{
                margin-left: 5px;
                transition:0.4s;
                &:hover{
                    opacity: 0.6;
                    transition:0.4s;
                }
                &:focus,&:active{
                    outline: none;
                    box-shadow: none;
                } 
                svg{
                    fill:#000;
                }
            }
        }
        @media screen and (max-width:425px){
            font-size: 17px;
        }
    }
    a{
        text-decoration: none;
    }
    .success-page-button-link{
      text-decoration: none;
    }
    .input-label{
        width: 97%;
    }
    @media screen and (max-width:1100px) {
        padding: 0 32px 0;
    }
    @media screen and (max-width: 500px) {
      padding: 0 15px 32px 15px;
    }
}