.header {
  padding: 32px;
  margin-bottom: 17px;
  &__logo {
    display: block;
    @media screen and (max-width: 991px) {
      width: 150px;
    }
    img{
      width: 100%;
    }
  }
  &__step {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -0.4px;
    position: relative;
    text-transform: uppercase;
    padding: 0 8px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      letter-spacing: 0;
    }
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        border: 1px solid black;
      }
  }
  &__link {
    text-decoration: none;
    color: #000;
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
  &__mmarine {
    img {
      object-fit: contain;
      max-height: 75px;
      @media screen and (max-width: 420px) {
        max-height: 50px;
        width: 100px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 13px 15px 25px;
  }
}