.certificate-success-page-container {
  padding: 0 45px;
  @media screen and (max-width: 500px){
    padding: 0;
  }
  .certificate-number {
    .small-button{
      margin: 20px auto auto;
    }
  }
  .success-page-button-container{
    display: flex;
    justify-content: center;
    .success-page-button-link{
      display: inline-block;
      text-decoration: none;
    }
  }
}
