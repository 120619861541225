.schedule {
  &.sport {
    label {
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 0 1.5rem;
    }
    @media screen and (max-width: 575px) {
      padding: 0 1rem;
    }
  }
  &__input-wrapper {
    label {
      position: relative;
      width: calc(100% - 17px);
      padding-left: 55px;
      border: none;
      box-shadow: none;
      cursor: pointer;
      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid black;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../../styles/assets/images/sv/tick_white.svg);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        border: none;
      }
    }
    input[type="checkbox"] {
      display: none;
      &:checked + label {
        &:before {
          background: #000;
        }
      }
      &:disabled + label {
        opacity: 0.5;
        cursor: default;
        user-select: none
      }
    }
  }
}