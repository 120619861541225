.schedule.school {
  padding: 0 1rem;
}
// чтобы дропдаун списка групп на любой высоте мобильного устройства, был на весь экран
// и было видно половину следующего элемента
$breakpoints:(
  'max-height: 900px': 640,
  'max-height: 860px': 590,
  'max-height: 820px': 540,
  'max-height: 780px': 500,
  'max-height: 740px': 450,
  'max-height: 690px': 410,
  'max-height: 650px': 360,
  'max-height: 600px': 310,
  'max-height: 550px': 260,
  'max-height: 500px': 210,
);

.school .selector__list {
  @media screen and (max-width: 991px) {
    @each $key,$val in $breakpoints{
      @media screen and (#{$key}) {
        max-height: #{$val}px;
      }
    }
  }
}
