#login {
  .reg {
    &-image {
      display: block;
      margin: 12px auto 8px;
    }
    &-title {
      font-size: 25px;
      line-height: 24px;
      margin-bottom: 15px;
    }
    &-subtitle {
      line-height: 15px;
      margin-bottom: 9px;
    }
  }

}

.login-link.return {
  display: block;
  margin: 10px auto;
  text-align: center;
}
