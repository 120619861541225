.link_button_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.link_button {
    display: inline-block;
    margin-right: 60px;
    position: relative;
    color: #000;
    &:hover {
        text-decoration: none;
        color:#000;
    }
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: #000;
        bottom: 3px;
    }
}
.sepatator {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c7c7c7;
    margin-top: 50px;
}
.block_title {
    display: block;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 25px;
    margin-top: 50px;
}
