#login .login {
  &-container {
    width: 100%;
    max-width: 375px;
    background: #fff;
    border-radius: 15px;
    margin: auto;
    padding: 39px 30px 40px;
    .btn {
      border-radius: 23px;
      padding: 13px 0 9px;
      margin-bottom: 9px;
      text-transform: uppercase;
      font-size: 15px;
      transition: .3s ease;
      &-primary {
        background: #333;
        border: 1px solid rgba(0, 0, 0, 0.5)
      }
      &-secondary {
        background: #fff;
        border-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 32px 32px rgba(0, 0, 0, 0.02), 0 16px 16px rgba(0, 0, 0, 0.02), 0 8px 8px rgba(0, 0, 0, 0.02), 0 4px 4px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.02);
        color: #101010;
      }
      &:hover {
        box-shadow: 0 13px 16px rgba(0, 0, 0, 0.1), 0 9.00525px 10.1598px rgba(0, 0, 0, 0.0787172), 0 5.87464px 6.11079px rgba(0, 0, 0, 0.0644315), 0 3.5172px 3.48921px rgba(0, 0, 0, 0.054344), 0 1.84198px 1.9312px rgba(0, 0, 0, 0.045656), 0 0.758017px 1.07289px rgba(0, 0, 0, 0.0355685), 0 0.174344px 0.550437px rgba(0, 0, 0, 0.0212828);
      }
      &:disabled:hover {
        box-shadow: none;
      }
      &:focus {
        box-shadow: none;
      } 
      &.social-btn {
        box-shadow: 0 32px 32px rgba(0, 0, 0, 0.02), 0 16px 16px rgba(0, 0, 0, 0.02), 0 8px 8px rgba(0, 0, 0, 0.02), 0 4px 4px rgba(0, 0, 0, 0.02), 0 2px 2px rgba(0, 0, 0, 0.02);
        width: 45px;
        height: 45px;
        border-radius: 15px;
        background: rgba(245, 245, 245, 1);
        border: 1px solid rgba(0, 0, 0, 0.05);
        position: relative;
        margin: 0 7px;
        background-repeat: no-repeat;
        background-position: center;
        transition: .3s ease;
        &.social-fb {
          background-image: url(../../../../styles/assets/images/sv/fb.svg);
        }
        &.social-gray-fb {
          padding: 0;
          color: #828282;
        }
        &.social-vk {
          background-image: url(../../../../styles/assets/images/sv/vk.svg);
        }
        &.social-google {
          background-image: url(../../../../styles/assets/images/sv/google.svg);
        }
        &.social-apple {
          background-image: url(../../../../styles/assets/images/sv/apple.svg);
        }
        &:hover {
          box-shadow: 0 13px 16px rgba(0, 0, 0, 0.1), 0 9.00525px 10.1598px rgba(0, 0, 0, 0.0787172), 0 5.87464px 6.11079px rgba(0, 0, 0, 0.0644315), 0 3.5172px 3.48921px rgba(0, 0, 0, 0.054344), 0 1.84198px 1.9312px rgba(0, 0, 0, 0.045656), 0 0.758017px 1.07289px rgba(0, 0, 0, 0.0355685), 0 0.174344px 0.550437px rgba(0, 0, 0, 0.0212828);
        }
        &:active {
          background-color: rgba(245, 245, 245, 1);
          border: 1px solid rgba(0, 0, 0, 0.05);
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .FBWarning {
      margin-top: 10px;
      color: #808080;
      font-size: 15px;
      text-align: center;
    }
    .login-link {
      border: none;
      background: transparent;
      color: #7b61ff;
      font-size: 15px;
    }
  }
  &-logo {
    text-align: center;
    font-size: 31px;
    line-height: 38px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  &-sub-logo {
    margin-bottom: 24px;
  }
  &-text {
    color: #808080;
    font-size: 15px;
    text-align: center;
  }
  &-input, &-phone-input {
    border: 2px solid #bfbfbf;
    height: 46px;
    padding: 15px 20px;
    font-size: 15px;
    &.has-error {
      border: 1px solid rgba(224, 0, 0, 0.75);
    }
    &:focus {
      box-shadow: none;
    }
  }
  &-phone-input {
    padding: 15px 20px 15px 48px;
  }
  &-label {
    margin-bottom: 4px;
    font-size: 15px;
  }
  &-row {
    margin-bottom: 13px;
    position: relative;
    &.pass-row {
      position: relative;
    }
  }
  &-link {
    text-decoration: underline;
    color: rgba(123, 97, 255, 1)
  }
  &-button-group {
    margin-top: 29px;
    margin-bottom: 18px;
  }
  &-social-text {
    margin-bottom: 10px;
  }
  &-pass-toggle {
    position: absolute;
    top: 37px;
    right: 10px;
    cursor: pointer;
    // &.visible {
    //   top: 38px;
    // }
  }
  &-separator {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after, &::before {
      content: '';
      display: block;
      background-color: #000;
      width: 45px;
      height: 1px;
      margin-top: 4px;
      margin-left: 12px;
    }
    &::before {
      margin-left: 0;
      margin-right: 12px;
    }
  }
}

#login {
  .flag-dropdown {
    margin-left: 20px;
    .selected-flag:hover {
      background-color: transparent;
    }
    &.open {
      background-color: transparent;
      .selected-flag {
        background-color: transparent;
      }
    }
  }
}
.login {
  .error-label {
    margin-top: 6px;
    font-size: 13px;
  }
}

#login input[type="checkbox"] {
  &:checked + label {
    &::after {
      content: url(../../../../styles/assets/images/sv/reg-tick.svg);
      display: block;
      position: absolute;
      top: 0;
      left: 2px;
    }
  }
  display: none;
  & + label {
    padding-left: 25px;
    position: relative;
    font-size: 13px;
    a {
      color: #7B61FF;
      text-decoration: underline;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      display: block;
      border: 1px solid rgba(123, 97, 255, 0.5);
      width: 14px;
      height: 14px;
      border-radius: 2px;
    }
  }
}