@mixin hoverEffect {
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.headerMenu {
  &_menuItem {
    color: black;
    &:hover {
      text-decoration: none;
    }
  }
  &_authButton {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    @include hoverEffect;
  }
  &_icon {
    @include hoverEffect;
  }
}