.members-form {
  
  & input {
    &.has-error {
      margin-bottom: -5px;
    }
    &::placeholder {
      color: rgba(204, 204, 204, 1);
    }
    &:focus {
      outline: none;
      box-shadow: none;
      background: none;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  & input[type="radio"] {
    display: none;
    & + label {
      position: relative;
      cursor: pointer;
      color: rgba(204, 204, 204, 1);
      margin-top: 3px;
      padding: 0 10px;
      font-size: 20px;
      &:nth-of-type(1) {
        margin-left: -10px;
      }
    }
    &:checked + label {
      color: #000;
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
      }
    }
  }
  & input[type="date"] {
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
}

select {
  margin-top: 4px;
  margin-left: -5px;
  margin-bottom: 6px;
  font-size: 20px;
  border: none;
  background: none;
  width: 100%;
  outline: none;
  display: block;
  color: #495057;
}

.member {
  padding: 0 21px 0 42px;
  @media screen and (max-width: 767px) {
    padding: 0 14px;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 28px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }
}

.input-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  &.radio {
    margin-top: 4px;
    margin-bottom: 3px;
  }
}

.form-control {
  border: none;
  background: none;
  padding: 0;
  font-size: 20px;
  height: calc(1.5em + 0.35rem + 2px);
}

.control-wrapper {
  margin-bottom: 12px;
  @media screen and (max-width: 767px) {
    margin-bottom: 3px;
  }
}

.error-label {
  font-size: 10px;
  line-height: 15px;
  display: inline-block;
  color: rgba(229, 0, 0, 1);
}

.validation-result {
  margin-left: 5px;
  vertical-align: baseline;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.radio {
  &-group {
    margin-left: 1.5rem;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
  &-item {
    margin-bottom: 20px;
  }
}


.members-form {
  input.radio-payment[type="radio"] {
    & + label {
      padding-left: 80px;
      margin-left: 0;
      color: #000;
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 50%;
        left: 40px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:checked + label {
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 50%;
        left: 40px;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #000;
      }
    }
  } 
}
