.arrivalFromUrl {
  padding: 0 35px;

  .arrival_item {
    margin-bottom: 20px;

    .arrival_item_value {
      display: block;
      padding: 11px 0;
      font-size: 20px;
    }
  }

}