.success-badge {
  background: url(../../../../styles/assets/images/sv/tick_white.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 50px;
  background-color: #02CC99;
  border-radius: 50%;
}