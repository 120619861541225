.mmarine-menu {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &__header {
    text-align: center;
    background: #000;
    color: #fff;
    display: flex;
    padding: 35px 30px;
    justify-content: space-between;
    font-size: 32px;
    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  &__logo {
    margin-right: auto;
    img {
      max-width: 85px;
    }
  }
  &__title {
    color: #fff;
    text-transform: uppercase;
    font-size: 33px;
    font-weight: bold;
    position: relative;
    margin-right: auto;
    margin-top: 50px;
    letter-spacing: -0.8px;
    margin-bottom: 44px;
    flex: 0 0 auto;
    @media screen and (max-width: 576px) {
      order: 3;
      margin: 50px auto;
      font-size: 25px;
      letter-spacing: -0.6px;
    }
    &:before {
      position: absolute;
      content: '';
      display: block;
      width: calc(100% + 67px);
      height: calc(100% + 72px);
      top: -38px;
      left: -33px;
      border: 6px solid #fff;
      border-radius: 58%;
      @media screen and (max-width: 576px) {
        width: calc(100% + 43px);
        height: calc(100% + 51px);
        top: -27px;
        left: -21px;
        border: 4px solid #fff;
      }
    }
  }
  &__personal {
    flex: 0 0 auto;
    min-width: 80px;
    @media screen and (max-width: 576px) {
      order: 2;
      flex: 1 0 auto;
      min-width: 100%;
    }
  }
  &__grid {
    flex: 1 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #000;
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
  &__link {
    display: flex;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    background-size: calc(35vw + 35vh);
    background-position: center center;
    transition: .3s ease;
    cursor: pointer;
    &--travel {
      background-image: url(../../../styles/assets/images/menu/travel_bg.png);
    }
    &--sport {
      background-image: url(../../../styles/assets/images/menu/sport_bg.png);
    }
    &--school {
      background-image: url(../../../styles/assets/images/menu/school_bg.png);
    }
    &--kids {
      background-image: url(../../../styles/assets/images/menu/kids_bg.png);
    }
    @media screen and (min-width: 1025px) {
      &:hover {
        color: #fff;
        background-size: calc(40vw + 40vh);
        text-decoration: none;
      }
    }
    @media screen and (orientation: portrait) {
      background-size: calc(50vw + 50vh);
      min-height: 240px;
    }
    &:hover {
      color: #fff;
    }
  }
  &__item {
    margin: auto;
    font-size: 20px;
  }
}