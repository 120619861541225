.subscription-input {
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
    margin-bottom: 0;
  }
}
.checkout{
  .sport_subscription_pay.subscription_pay{
    .subscription_info {
      margin-left: 0;
      padding: 10px 0;
    }
    .subscription-input{
      margin-right: 17px;
    }
  }
  .timer {
    padding: 0 30px 0 55px;
    @media screen and (max-width: 767px) {
      padding: 0 15px;
    }
  }
}
.checkout{
  .radio-input{
    img{
      margin-left: 10px;
    }
  }
}