.priceReminder{
    .priceReminder__title{
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: -0.1px;
        opacity: 0.5;
        text-transform: uppercase;
    }
    .priceReminder__value{
        font-weight: bold;
        font-size: 1.2rem;
    }
}