.certificate{
  width: 450px;
  color: #fff;
  border-radius: 30px;
  border: 3px solid #FFFFFF;
  margin-top: 40px;
  position: relative;
  .certificate-wrapped{
    padding: 40px 40px 18px 40px;
  }
  .certificate-title-block {
    display: flex;
  }
  .certificate-graphic-block {
    padding: 15px 30px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;

    .certificate-graphic{
      position: absolute;
      width: 120px;
      background-color: #1C1A1D;
      font-size: 40px;
      font-weight: bold;
      border-radius: 50%;
      border: 3px solid #fff;
      text-align: center;
      transform: rotate(-29deg);
      padding: 7px 0 13px 0;
      top: 5%;
      right: 10%;
    }
  }
  .certificate-title{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 37px;
  }
  .field{
    margin-top: 30px;
    .field-label{
      display: block;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      color: #F9F9F9;
      opacity: 0.5;
    }
    .field-value{
      font-weight: bold;
      font-size: 20px;
      line-height: 22px;
      text-transform: uppercase;
      color: #F9F9F9;
      margin-top: 6px;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
    }
  }
  .certificate-code{
    padding: 0px 40px 23px 40px;
    &.certificate-code-success-page{
      background: #205CDC;
      padding: 20px 40px 23px 40px;
    }
    .field{
      margin-top: 0;
    }
    .certificate-code-value{
      display: block;
      font-weight: bold;
      font-size: 30px;
      line-height: 1;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-top: 12px;
      word-break: break-all;
    }
  }
  .certificate-desc{
    margin-top: 20px;
    font-weight: bold;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: #F9F9F9;
    opacity: 0.5;
  }
  .certificate-sum{
    position: relative;
    background: #FFF;
    padding: 15px 30px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    .certificate-qr_en {
      position: absolute;
      height: 90px;
      width: 90px;
      right: 10%;
      top: 5px;
    }
    .certificate-graphic{
      position: absolute;
      width: 120px;
      background-color: #1C1A1D;
      font-size: 40px;
      font-weight: bold;
      border-radius: 50%;
      border: 3px solid #fff;
      text-align: center;
      transform: rotate(-29deg);
      top: -27px;
      right: -7px;
      padding: 7px 0 13px 0;
    }
    .certificate-sum-title{
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
      color: #000000;
      opacity: 0.3;
    }
    .certificate-sum-value{
      font-weight: bold;
      font-size: 40px;
      line-height: 52px;
      text-transform: uppercase;
      color: #000000;
    }
  }
  @media screen and (max-height: 700px) {
    position: sticky;
    top: -20vh;
  }
}
