@mixin tabletHeight {
  @media screen and (max-height: 650px){
    @content
  }
}
@mixin smallTabletHeight {
  @media screen and (max-height: 530px){
    @content
  }
}
@mixin leftBorder {
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      left: calc(100% / 4 * #{$i} - 2px);
    }
  }
} 

@mixin difficulty {
  @for $i from 1 through 4 {
    &.difficulty-#{$i} {
      .difficulty__graph {
        &::after {
          width: calc(100% - 100% / 4 * #{$i});
        }
      }
    }
  }
}

.card-label {
  color: red;
}

.booking-card {
  max-width: 450px;
  margin: 33px auto;
  font-family: 'Basis Grotesque Pro', sans-serif;
  .applied-promocode {
    right: 25px;
  }
  .booking-card-linearBg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.686712) 120%);
  }
}

.primary-info {
  min-height: 188px;
  background: url(../../../styles/assets/images/card/card-bg.jpg);
  background-size: cover;
  padding: 17px 30px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-radius: 25px 25px 0 0;
  position: relative;
  @include tabletHeight {
    min-height: 125px;
  }
  @include smallTabletHeight {
      min-height: 90px;
  }
}

.card-label {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #fff;
  opacity: 0.5;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-bottom: 9px;
}

.main-info-label {
  margin-bottom: 6px;
  z-index: 1;
}

.card-caption {
  color: #fff;
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  &--small {
    font-size: 16px;
  }
  @include tabletHeight{
    font-size: 18px;
    line-height: 1;
  }
}

.course-date {
  color: #fff;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.1px;
  z-index: 1;
}

.card-course {
  margin-bottom: 5px;
  z-index: 1;
}

.secondary-info {
  background: #000;
  padding: 25px 50px 17px 30px;
  @include smallTabletHeight{
      padding: 15px 50px 10px 30px;
  }
}

.difficulty {
  &__label {
    margin-bottom: 9px;
  }
  &__graph {
    padding: 2px 0 3px;
    z-index: 5;
    background: #323232;
    border-radius: 8px;
    position: relative;
    margin-bottom: 14px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, #E30303 0%, #FFE600 calc(100% / 2), #65D753 calc(100%));
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 8px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      background: #323232;
      border-radius: 8px;
      top: 0;
      right: 0;
      z-index: 10;
    }
    .difficulty-divider {
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      background: #000;
      z-index: 20;
      @include leftBorder;
    }
  }
  @include difficulty;
  &__caption {
    margin-bottom: 9px;
  }
  &__description {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
  }
}

.weather {
  display: flex;
  .temperature {
    flex: 0 0 53%;
    &__value-air {
      margin-bottom: 6px;
    }
  }
}

.participants-info {
  padding: 22px 50px 17px 30px;
  background: #000;
  position: relative;
  .promocode-badge {
    position: absolute;
    top: -10px;
    right: 62px;
  }
  @include tabletHeight{
    padding: 10px 50px 10px 30px;
  }
}

.participants-icons {
  img {
    margin-right: 10px;
    margin-bottom: 5px;
  }
}

.price-info {
  padding: 18px 20px 15px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 0 0 30px 30px;
  &.paid {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      display: block;
      background-image: url(../../../styles/assets/images/sv/paid.svg);
      background-position: 5px 1px;
      background-repeat: no-repeat;
      background-size: 267px;
      width: 100%;
      height: 300px;
      z-index: 10;
      left: 151px;
      top: 16px;
      transform: rotate(17deg);
    }
  }
  &.paid_en:after {
    background-image: url(../../../styles/assets/images/sv/paid-en.svg);
  }
  @include tabletHeight{
    padding: 10px 20px 10px 30px;
  }
}

.price-label {
  color: #000;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-bottom: 10px;
  @include tabletHeight{
    margin-bottom: 10px;
  }
}

.old-price {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  margin-right: 9px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    top: calc(57% - 2px);
    left: 0;
    background: #1140FF;
  }
}

.price-row {
  display: flex;
  align-items: baseline;
}

.price {
  font-size: 40px;
  line-height: 1;
  font-weight: bold;

  .free {
    font-size: 30px;
  }
  @include tabletHeight{
    font-size: 30px;
  }
}

.price-discount-label {
  color: rgba(17, 64, 255, 1);
  font-size: 12px;
  margin-top: 10px;
}

.price-localized {
  color: rgba(179, 179, 179, 1);
  font-size: 20px;
  font-weight: 500;
  padding: 11px 31px;
  flex: 0 0 auto;
  border: 1px solid #b3b3b3;
  border-radius: 50%;
  margin-top: 6px;
}

.card-hole {
  position: absolute;
  top: 30px;
  left: calc(50% - 42px);
  width: 84px;
  height: 20px;
  background: rgba(29, 29, 29, 1);
  border-radius: 20px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -10px;
    left: calc(50% - 10px);
    background: rgba(29, 29, 29, 1);
  }
}

.human-pic {
  margin-bottom: 5px;
  display: block;
}
.price_reminder{
  width: 100%;
  span{
    margin-left: 5px;
  }
}