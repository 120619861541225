.discount {
  &__container-group {
    background: url(../../../../styles/assets/images/sv/discount.svg);
    position: absolute;
    bottom: 18px;
    right: 135px;
    width: 98px;
    height: 54px;
    background-repeat: no-repeat;
    transform: rotate(-15deg);
    font-family: 'Menoe Grotesque Pro', sans-serif;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: bold;
    padding: 8px 12px;
    line-height: 10px;
    z-index: 30;
    .discount__text {
      max-width: 46px;
      position: relative;
      &:before {
        content: '';
      }
    }
  }
  &__container-keel {
    background: url(../../../../styles/assets/images/sv/keel.png);
    background-repeat: no-repeat;
    width: 111px;
    height: 59px;
    position: absolute;
    right: 22px;
    top: 28px;
    transform: rotate(-15deg);
    padding: 5px 7px;
    z-index: 20;
    .discount__value {
      text-align: center;
      font-family: 'DrukWide', sans-serif;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 17px;
    }
    .discount__text {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: 12px;
      max-width: 100%;
      text-transform: uppercase;
      text-align: center;
      line-height: 15px;
      font-weight: bold;
      &:before {
        content: '';
      }
    }
  }

  &__container-race {
    display: none;
  }

  &__container-individual {
    background: url(../../../../styles/assets/images/sv/luch.png);
    background-repeat: no-repeat;
    width: 161px;
    height: 57px;
    position: absolute;
    right: 25px;
    bottom: 42px;
    transform: rotate(15deg);
    padding: 5px 10px;
    z-index: 10;
    .discount__value {
      text-align: right;
      font-family: 'DrukWide', sans-serif;
      font-size: 14px;
      line-height: 19px;
    }
    .discount__text {
      font-family: 'Basis Grotesque Pro', sans-serif;
      font-size: 12px;
      max-width: 100%;
      text-transform: uppercase;
      text-align: right;
      line-height: 15px;
      font-weight: bold;
      &:before {
        content: '';
      }
    }
  }
}