.review{
  .rating {
    margin-bottom: 25px;
    // стили при наведении
    label {
      margin: 10px;
      opacity: 0.2;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        // текущему и всем последующим, так как действует row-reverse
        &, ~ label {
          opacity: 1;
        }
      }
    }
    // стили при состоянии checked
    input:checked ~ label{
      opacity: 1;
    }
    // перебиваем другой стиль
    input, input:checked{
      display: none;
      & ~ label {
        padding: 0;
        &:before, &:after{
          display: none;
        }
      }
    }

    .ratingType{
      display: block;
      text-align: center;
      height: 16px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
  .inputFeedback{
    background: none;
    box-shadow: none;
  }
  .sendButton{
    margin-top: 60px;
  }
}
