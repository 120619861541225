.radio {
  &-group {
    margin-left: 1.5rem;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
  &-item {
    margin-bottom: 20px;
  }
}


.members-form {
  input.radio-payment[type="radio"] {
    & + label {
      padding-left: 80px;
      margin-left: 0;
      color: #000;
      &::before {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 50%;
        left: 40px;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:checked + label {
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 50%;
        left: 40px;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #000;
      }
    }
  }
  .error-text__ageConsent,
  .default-text__ageConsent{
    display: block;
    width: 75%;
    @media screen and (max-width:400px) {
      width: 100%;
    }
  }
  .default-text__ageConsent{
    font-size: 10px;
    line-height: 15px;
  }
}

.control-wrapper {
  .input-label {
    margin-bottom: 0;
  }
  .react-tel-input {
    .form-control {
      width: 100%;
      border: none;
      background: none;
      font-family: 'BASIS GROTESQUE PRO', sans-serif;
      font-size: 16px;
      padding-left: 35px;
    }
    .flag-dropdown {
      border: none;
      background: none;
    }
    .selected-flag {
      padding: 0;
    }
  }
}
