.item {
  &__container {
    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
  &__element {
    color: #1d1d1d;

    &-image {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
    }
    &:nth-child(1) {
      &.item__skip_next {
        border-right: none;
      }
    }
    &:nth-child(2) {
      @media screen and (max-width: 500px) {
        flex: 0 0 0;
        border: none;
      }
    }
    &:nth-child(3) {
      @media screen and (max-width: 500px) {
        margin-top: 0;
        flex: 1 0 80px;
      }
    }

  }
}