.schedule {
  padding: 0 32px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  input[type=radio] {
    display: none;
  }
  label {
    margin-bottom: 0;
    padding: 11px 15px 13px;
    border: 1px solid rgba(229, 229, 229, 1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 12px;
    border-radius: 13px;
    transition: .4s ease;
    @media screen and (max-width: 767px) {
      padding: 0px 16px;
    }
    &:hover {
      border-color: rgba(17, 64, 255, 0.46);
    }
  }
  input[type=radio]:checked {
    & + label {
      border-color: rgba(17, 64, 255, 0.46);
      & .schedule-item {
        &__title {
          color: rgba(17, 64, 255, 1);
        }
        &__description {
          color: rgba(17, 64, 255, 1)
        }
        &__places-info {
          color: rgba(17, 64, 255, 1);
          border-color: rgba(17, 64, 255, 1);
        }
      }
    }
  }
  &__item {
    @media screen and (max-width: 767px) {
      padding: 0px 15px;
    }
    & * {
      cursor: pointer;
    }
  }
  &__label {
    margin-left: 25px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 8px;
    @media screen and (max-width: 767px) {
      margin-left: 15px;
      margin-top: 4px;
    }
    &:nth-of-type(1) {
      margin-top: 0;
    }
  }
  &__group {
    margin-bottom: 20px;
  }
  
  &-item {
    transition: .4s ease;
    &__title {
      font-size: 20px;
      line-height: 35px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }
    &__description {
      font-size: 14px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    &-desc{
      word-break: break-all;
    }
    &__main-info {
      padding: 6px 24px;
      @media screen and (max-width: 767px) {
        padding: 15px 13px 12px;
      }
    }
    &__places-info {
      font-size: 20px;
      padding-top: 8px;
      border-left: 1px solid #e5e5e5;
    }
  }
}
