.entityInfo{
  margin-top: 10px;
  .entityInfo_item{
    font-size: 16px;
    b{
      margin-left: 4px;
    }
  }
  .entityInfo_name{
    margin-top: 5px;
    font-size: 16px;
    color: #01A830;
  }
  .deleteEntity{
    width: 12px;
    height: 12px;
    margin-left: 7px;
    display: block;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    .deleteEntity_svg{
      display: block;
      width: 12px;
      height: 12px;
      path{
        fill: #d62323;
      }
    }
  }
}