.final {
  padding: 4px 32px;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
  &__title {
    white-space: pre-line;
    &.form-title {
      margin-bottom: 42px;
      @media screen and (max-width: 767px) {
        margin-bottom: 28px;
      }
    }
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  &__docs-list {
    margin-top: 38px;
    @media screen and (max-width: 767px) {
      margin-top: 28px;
    }
  }
  .divider {
    @media screen and (max-width: 767px) {
      margin-top: 27px;
    }
  }
  &__docs-item {
    display: block;
    padding-left: 33px;
    font-size: 20px;
    text-decoration: underline;
    margin-bottom: 4px;
    position: relative;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 8px;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      background: url('../../../styles/assets/images/sv/download.svg');
      top: -8px;
      left: -9px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__input-wrapper {
    margin-bottom: 6px;
    & .title-label {
      color: black;
      font-weight: bold;
      margin-bottom: 0;
      letter-spacing: 0;
    }
    &.input-wrapper--radio {
      &:nth-child(7) {
        margin-bottom: 30px;
        @media screen and (max-width: 767px) {
          margin-bottom: 19px;
        }
      }
    }
  }
  input[type="text"] {
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
      font-size: 18px;
    }
  }
  input[type="radio"] {
    display: none;
    & + label {
      color: #000;
      font-size: 20px;
      position: relative;
      padding-left: 33px;
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border: 1px solid black;
        border-radius: 50%;
        top: 2px;
        left: 0;
      }
    }
    &:checked + label {
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: #000;
        border: 1px solid black;
        border-radius: 50%;
        top: 5px;
        left: 3px;
      }
    }
  }
  a{
    text-decoration: none;
  }
}