.certificate-container{
  @media screen and (max-height: 700px) {
    position: relative;
    .certificate-render{
      position: absolute;
      height: 100%;
    }
  }
  @media screen and (max-height: 800px){
    .certificate-render.certificate-render-height-auto{
      position: static;
      margin-left: 0;
      padding-bottom: 20px;
    }
  }
  .certificate-register {
    background-color: #F9F9F9;
  }
  .certificate-render {
    background-color: #000;
  }
}