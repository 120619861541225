$body-color: #000;
$border-color: #000;

$colors: (
        gray:             #434a54,
        gray-light:       #aab2bd,
        gray-lighter:     #e8eff4,
        gray-lightest:    #e9e8ee,
        gray-dark:        #0f1531,
        black:            #000000
);