.discount {
  background: url(../../../../styles/assets/images/sv/discount.svg);
  position: absolute;
  right: 20px;
  width: 98px;
  height: 54px;
  background-repeat: no-repeat;
  transform: rotate(-15deg);
  font-family: 'Menoe Grotesque Pro', sans-serif;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: bold;
  padding: 8px;
  line-height: 10px;
  &__text {
    max-width: 46px;
    position: relative;
    &::before {
      position: absolute;
      content: '>>>';
      display: block;
      position: absolute;
      top: 0;
      left: 40px;
      font-size: 10px;
    }
  }

  &.coachless {
    bottom: 20px;
  }
  &.full-book {
    bottom: 100px;
  }
}