.item {
  &__container {
    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
  &__element {
    color: #1d1d1d;
    &:nth-child(1) {
      flex: 0 0 100px;
      border-right: 2px solid #757575;
      margin-right: 5px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 500px) {
        flex: 0 0 87px;
      }
    }
    &:nth-child(2) {
      flex: 0 0 160px;
      border-right: 2px solid #757575;
      margin-right: 5px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 500px) {
        flex: 0 0 110px;
        border: none;
      }
    }
    &:nth-child(3) {
      flex: 1 0 80px;
      font-size: 12px;
      line-height: 37px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 500px) {
        margin-top: 10px;
        flex: 1 0 100%;
      }
    }
  }
  &__time {
    font-size: 26px;
  }
  &__event {
    word-wrap: break-word;
    line-height: 15px;
    max-width: calc(100% - 30px);
  }
  &__places {
    font-size: 26px;
    margin-right: 5px;
    &-label {
      font-size: 12px;
      max-width: 65px;
      display: inline-block;
      vertical-align: baseline;
      line-height: 12px;
    }
  }
}

.selector {
  &__item {
    padding: 11px 10px;
    border-bottom: 1px solid black;
  }
  &__trigger {
    &.disabled {
      border: none;
      padding: 11px 0;
      background: none;
      font-size: 20px;
      cursor: default;
    }
  }
  
}