.subscription {
  margin: 0px 32px 15px 56px;
  @media screen and (max-width: 991px) {
    margin: 0px 15px 0 25px;
  }
  &-title {
    margin-bottom: 40px;
    line-height: 24px;
  }
  &-text {
    margin-bottom: 0;
    font-size: 16px;
    span {
      font-weight: bold;
      
    }
  }
  .checkout__input-wrapper {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 40px;
    &:before {
      display: none;
    }
  }

  .range-block-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .range-block {
    min-width: 400px;
    margin: 0 15px;
    position: relative;
    @media screen and (max-width: 991px) {
      width: calc(100% - 40px);
      min-width: auto;
    }
  }

  input[type=range] {
    height: 26px;
    -webkit-appearance: none;
    margin: 10px 0 40px;
    width: 100%;
    position: relative;
    max-width: 400px;
    background: transparent;
    @media screen and (max-width: 991px) {
      max-width: none;
    }
  }
  .range-minimum {
    position: absolute;
    bottom: 10px;
    font-size: 16px;
    @media screen and (max-width: 991px) {
      font-size: 13px;
      bottom: 17px;
      left: -10px;
    }
  }
  .range-maximum {
    position: absolute;
    bottom: 10px;
    right: -30px;
    font-size: 16px;
    @media screen and (max-width: 991px) {
      font-size: 13px;
      bottom: 17px;
      right: -20px;
    }
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #C4C4C4;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #FFFFFF;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #C4C4C4;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50px;
    background: #C4C4C4;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #FFFFFF;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #FFFFFF;
  }

  .price-block {
    display: none;
    .price-info{
      background: none;
      padding: 0;
    }
    @media screen and (max-width: 991px) {
      display: block;
    }
  }
  .radio-input {
    img {
      margin-left: 10px;
    }
  }
}
.certificateError{
  font-size: 16px;
}
.subscription_popup_text {
  .subscription_popup_text_item{
    margin-top: 10px;
  }
  .subscription_popup_agree{
    display: block;
    margin-top: 10px;
    color: #737373;
    font-size: 17px;
  }
  .subscription_popup_hr{
    display: block;
    width: 100px;
    height: 1px;
    background: #dcdcdc;
    margin: 5px 0;
  }
}

.certificate {
  &_balance {
    color: #01A830;
    font-size: 18px;
  }
}
