.popup{
  .modal-content{
    border-radius: 25px;
    border: none;
    padding: 10px;
  }
  .popup_button{
    background: #000;
    border: none;
  }
  .button_close{
    background: none;
    border: none;
    margin: 7px;
  }
}
